import React from 'react'
import { IconsProps } from 'msp-integrations'

const SearchIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 24 24'
    >
      <path d="m22 21.31-5.17-5.17a8.47 8.47 0 0 0 2.18-5.66c0-4.69-3.82-8.5-8.5-8.5S2 5.8 2 10.49c0 4.69 3.82 8.5 8.5 8.5 2.16 0 4.12-.81 5.62-2.14l5.17 5.17zM3 10.49c0-4.14 3.37-7.5 7.5-7.5s7.5 3.37 7.5 7.5-3.37 7.5-7.5 7.5S3 14.62 3 10.49z" />
    </svg>
  )
}

export default SearchIcon
